import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type AddEventInput = {
  count: Scalars['Int'];
  crossingId?: InputMaybe<Scalars['Int']>;
  plotCode?: InputMaybe<Scalars['String']>;
  plotId?: InputMaybe<Scalars['Int']>;
  reservationId?: InputMaybe<Scalars['Int']>;
  type: EventType;
};

export type AddEventResponse = {
  __typename?: 'AddEventResponse';
  event?: Maybe<Event>;
  status: MutationStatus;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Crossing = {
  __typename?: 'Crossing';
  activeReservationCount: Scalars['Int'];
  code: Scalars['String'];
  count: Scalars['Int'];
  failed: Scalars['Float'];
  father?: Maybe<Plot>;
  hasActiveReservation: Scalars['Boolean'];
  id: Scalars['Int'];
  mother?: Maybe<Plot>;
  priority: Scalars['Int'];
  program: Scalars['String'];
  reservations: Array<Reservation>;
  reserved: Scalars['Int'];
  result: Scalars['Float'];
  seedsCounted: Scalars['Int'];
  seedsCountedToday: Scalars['Int'];
  seedsOrdered: Scalars['Int'];
  site: Scalars['String'];
  siteId?: Maybe<Scalars['Int']>;
};

export type DeleteProgramInput = {
  id: Scalars['Int'];
};

export type DeleteProgramResponse = {
  __typename?: 'DeleteProgramResponse';
  status: MutationStatus;
};

export type Event = {
  __typename?: 'Event';
  count: Scalars['Int'];
  crossing?: Maybe<Crossing>;
  crossingId?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  delta: Scalars['Int'];
  id: Scalars['Int'];
  plot?: Maybe<Plot>;
  plotId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  type: EventType;
  user?: Maybe<Scalars['String']>;
};

export type EventTotal = {
  __typename?: 'EventTotal';
  total: Scalars['Int'];
  type: EventType;
};

export enum EventType {
  Collect25 = 'COLLECT25',
  Collect50 = 'COLLECT50',
  Collect75 = 'COLLECT75',
  Collect100 = 'COLLECT100',
  CorrectSeeds = 'CORRECT_SEEDS',
  CountSeeds = 'COUNT_SEEDS',
  Crossing = 'CROSSING',
  EditCount = 'EDIT_COUNT',
  EditData = 'EDIT_DATA',
  EditPosition = 'EDIT_POSITION',
  EditPriority = 'EDIT_PRIORITY',
  EditProgram = 'EDIT_PROGRAM',
  EditSeedsOrder = 'EDIT_SEEDS_ORDER',
  EditSpares = 'EDIT_SPARES',
  Emasculate = 'EMASCULATE',
  EpIn = 'EP_IN',
  EpOut = 'EP_OUT',
  EpReturn = 'EP_RETURN',
  Fail = 'FAIL',
  Import = 'IMPORT',
  Lost25 = 'LOST25',
  None = 'NONE',
  Remove = 'REMOVE',
  Reserve = 'RESERVE',
  Selfing = 'SELFING',
  Unremove = 'UNREMOVE',
  Unreserve = 'UNRESERVE'
}

export type Export = {
  __typename?: 'Export';
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  rows: Array<ExportRow>;
};

export type ExportRow = {
  __typename?: 'ExportRow';
  breedingOperation?: Maybe<Scalars['String']>;
  breedingOperationType?: Maybe<Scalars['String']>;
  count: Scalars['Float'];
  date: Scalars['DateTime'];
  plot: Scalars['String'];
};

export type FindEventsInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  plot?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type GenerateExportPayload = {
  __typename?: 'GenerateExportPayload';
  export?: Maybe<Export>;
  status: MutationStatus;
};

export type GetRolePayload = {
  __typename?: 'GetRolePayload';
  role: UserRole;
};

export type ImportSourceDataResult = {
  __typename?: 'ImportSourceDataResult';
  error?: Maybe<Scalars['String']>;
  fieldsChanged: Scalars['Int'];
  rowsAdded: Scalars['Int'];
};

export type ImportSourceDataRowInput = {
  breedingOperation: Scalars['String'];
  breedingOperationType: Scalars['String'];
  code: Scalars['String'];
  count: Scalars['Int'];
  fatherPlot?: InputMaybe<Scalars['String']>;
  fatherPosition?: InputMaybe<Scalars['String']>;
  fatherRemoved?: InputMaybe<Scalars['DateTime']>;
  fatherSpares?: Scalars['Int'];
  generation?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  motherPlot: Scalars['String'];
  motherPosition: Scalars['String'];
  motherRemoved?: InputMaybe<Scalars['DateTime']>;
  origin?: InputMaybe<Scalars['String']>;
  priority: Scalars['Int'];
  program: Scalars['String'];
  seeds: Scalars['Int'];
  site: Scalars['String'];
  stage?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEvent: AddEventResponse;
  deleteProgram: DeleteProgramResponse;
  generateExport: GenerateExportPayload;
  importSourceData: ImportSourceDataResult;
  updateEvent: AddEventResponse;
  updateProgram: UpdateProgramResponse;
  updateSite: UpdateSiteResponse;
  updateUser: UpdateUserPayload;
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};


export type MutationDeleteProgramArgs = {
  input: DeleteProgramInput;
};


export type MutationImportSourceDataArgs = {
  input: Array<ImportSourceDataRowInput>;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateProgramArgs = {
  input: UpdateProgramInput;
};


export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export enum MutationStatus {
  Locked = 'LOCKED',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export type Plot = {
  __typename?: 'Plot';
  available: Scalars['Int'];
  code: Scalars['String'];
  counts: PlotCounts;
  crossingsFather: Array<Crossing>;
  crossingsMother: Array<Crossing>;
  dates: PlotDates;
  eventTotals: Array<EventTotal>;
  id: Scalars['Int'];
  position?: Maybe<Position>;
  program?: Maybe<Scalars['String']>;
  reserved: Scalars['Int'];
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  spareCount: Scalars['Int'];
  type: PlotType;
  year: Scalars['Int'];
};

export type PlotCounts = {
  __typename?: 'PlotCounts';
  available: Scalars['Int'];
  reserved: Scalars['Int'];
  toCollect: Scalars['Int'];
  toEmasculate: Scalars['Int'];
  toSelf?: Maybe<Scalars['Int']>;
};

export type PlotDates = {
  __typename?: 'PlotDates';
  done?: Maybe<Scalars['DateTime']>;
  onHold?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
};

export enum PlotFilter {
  None = 'NONE',
  Priority = 'PRIORITY',
  Reserved = 'RESERVED'
}

export enum PlotType {
  Father = 'FATHER',
  Mother = 'MOTHER',
  None = 'NONE'
}

export type Position = {
  __typename?: 'Position';
  id: Scalars['Int'];
  locationNumber: Scalars['Int'];
  plots: Array<Plot>;
  table: Scalars['Int'];
};

export type Program = {
  __typename?: 'Program';
  activeEmasculationPriorities: Array<Scalars['Int']>;
  code: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  priorities: Array<Scalars['Int']>;
  year: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  crossing: Crossing;
  crossings: Array<Crossing>;
  events: Array<Event>;
  export: Export;
  exports: Array<Export>;
  findEvents: Array<Event>;
  plot: Plot;
  plotByCode: Plot;
  plots: Array<Plot>;
  position: Position;
  positions: Array<Room>;
  programs: Array<Program>;
  role: GetRolePayload;
  sites: Array<Site>;
  /** @deprecated Use GetSites */
  totals: Total;
  users: Array<User>;
};


export type QueryCrossingArgs = {
  crossingCode: Scalars['String'];
};


export type QueryEventsArgs = {
  crossingId?: InputMaybe<Scalars['Int']>;
  plotId?: InputMaybe<Scalars['Int']>;
};


export type QueryExportArgs = {
  id: Scalars['Int'];
};


export type QueryFindEventsArgs = {
  input: FindEventsInput;
};


export type QueryPlotArgs = {
  plotId: Scalars['Int'];
};


export type QueryPlotByCodeArgs = {
  plotCode: Scalars['String'];
  type: PlotType;
};


export type QueryPlotsArgs = {
  filter?: PlotFilter;
  includeRemoved?: Scalars['Boolean'];
  positionId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  type?: PlotType;
};


export type QueryPositionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPositionsArgs = {
  siteId?: InputMaybe<Scalars['Int']>;
  type: PlotType;
};


export type QueryTotalsArgs = {
  siteId?: InputMaybe<Scalars['Int']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  count: Scalars['Int'];
  crossingId: Scalars['Int'];
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export type Room = {
  __typename?: 'Room';
  locationNumber: Scalars['Int'];
  positions: Array<Position>;
};

export type Site = {
  __typename?: 'Site';
  activeDates: Array<Scalars['DateTime']>;
  emasculatedToday: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eventUpdated: Event;
  eventUpdatedByCrossing: Event;
  eventUpdatedByPlot: Event;
};


export type SubscriptionEventUpdatedByCrossingArgs = {
  crossingId: Scalars['Int'];
};


export type SubscriptionEventUpdatedByPlotArgs = {
  plotId: Scalars['Int'];
};

export type Total = {
  __typename?: 'Total';
  emasculated: Scalars['Int'];
  id: Scalars['Int'];
};

export type UpdateEventInput = {
  count?: InputMaybe<Scalars['Int']>;
  delta?: InputMaybe<Scalars['Int']>;
  eventId: Scalars['Int'];
};

export type UpdateProgramInput = {
  activeEmasculationPriorities: Array<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
};

export type UpdateProgramResponse = {
  __typename?: 'UpdateProgramResponse';
  program: Program;
  status: MutationStatus;
};

export type UpdateSiteInput = {
  activeDates?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id: Scalars['Int'];
};

export type UpdateSiteResponse = {
  __typename?: 'UpdateSiteResponse';
  site: Site;
  status: MutationStatus;
};

export type UpdateUserInput = {
  role: UserRole;
  userId: Scalars['Int'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  status: MutationStatus;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  role: UserRole;
};

export enum UserRole {
  Admin = 'ADMIN',
  Normal = 'NORMAL'
}

export type CrossingInfoFragment = { __typename?: 'Crossing', id: number, code: string, count: number, result: number, priority: number, program: string, site: string, seedsOrdered: number, seedsCounted: number, father?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null, mother?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null };

export type EventFragment = { __typename?: 'Event', id: number, type: EventType, count: number, user?: string | null, date: any, plotId?: number | null, crossingId?: number | null, delta: number, plot?: { __typename?: 'Plot', code: string } | null };

export type LogEventFragment = { __typename?: 'Event', id: number, type: EventType, count: number, date: any, user?: string | null, plot?: { __typename?: 'Plot', id: number, code: string } | null, crossing?: { __typename?: 'Crossing', id: number, code: string } | null };

export type PlotFragment = { __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } };

export type PlotWithCrossingsFatherFragment = { __typename?: 'Plot', id: number, code: string, year: number, available: number, reserved: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, crossingsFather: Array<{ __typename?: 'Crossing', mother?: { __typename?: 'Plot', code: string, available: number, position?: { __typename?: 'Position', id: number, locationNumber: number, table: number } | null } | null, reservations: Array<{ __typename?: 'Reservation', id: number, crossingId: number, date: any, count: number }> }> };

export type PlotWithEventTotalsFragment = { __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, eventTotals: Array<{ __typename?: 'EventTotal', type: EventType, total: number }>, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } };

export type ProgramFragment = { __typename?: 'Program', id: number, code: string, year: number, priorities: Array<number>, activeEmasculationPriorities: Array<number>, endDate?: any | null };

export type SiteFragment = { __typename?: 'Site', id: number, name: string, activeDates: Array<any> };

export type UserFragment = { __typename?: 'User', id: number, name: string, email: string, role: UserRole };

export type AddEventMutationVariables = Exact<{
  crossingId: Scalars['Int'];
  type: EventType;
  count: Scalars['Int'];
  reservationId?: InputMaybe<Scalars['Int']>;
}>;


export type AddEventMutation = { __typename?: 'Mutation', addEvent: { __typename?: 'AddEventResponse', status: MutationStatus } };

export type DeleteProgramMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProgramMutation = { __typename?: 'Mutation', deleteProgram: { __typename?: 'DeleteProgramResponse', status: MutationStatus } };

export type GenerateExportMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateExportMutation = { __typename?: 'Mutation', generateExport: { __typename?: 'GenerateExportPayload', status: MutationStatus, export?: { __typename?: 'Export', id: number, date: any } | null } };

export type ImportSourceDataMutationVariables = Exact<{
  input: Array<ImportSourceDataRowInput> | ImportSourceDataRowInput;
}>;


export type ImportSourceDataMutation = { __typename?: 'Mutation', importSourceData: { __typename?: 'ImportSourceDataResult', rowsAdded: number, fieldsChanged: number, error?: string | null } };

export type UpdateEventMutationVariables = Exact<{
  eventId: Scalars['Int'];
  delta: Scalars['Int'];
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'AddEventResponse', status: MutationStatus } };

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['Int'];
  activeEmasculationPriorities: Array<Scalars['Int']> | Scalars['Int'];
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type UpdateProgramMutation = { __typename?: 'Mutation', updateProgram: { __typename?: 'UpdateProgramResponse', status: MutationStatus, program: { __typename?: 'Program', id: number, code: string, year: number, priorities: Array<number>, activeEmasculationPriorities: Array<number>, endDate?: any | null } } };

export type UpdateSiteMutationVariables = Exact<{
  id: Scalars['Int'];
  activeDates: Array<Scalars['Date']> | Scalars['Date'];
}>;


export type UpdateSiteMutation = { __typename?: 'Mutation', updateSite: { __typename?: 'UpdateSiteResponse', status: MutationStatus, site: { __typename?: 'Site', id: number, name: string, activeDates: Array<any> } } };

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  role: UserRole;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UpdateUserPayload', status: MutationStatus } };

export type FindEventsQueryVariables = Exact<{
  input: FindEventsInput;
}>;


export type FindEventsQuery = { __typename?: 'Query', findEvents: Array<{ __typename?: 'Event', id: number, type: EventType, count: number, date: any, user?: string | null, plot?: { __typename?: 'Plot', id: number, code: string } | null, crossing?: { __typename?: 'Crossing', id: number, code: string } | null }> };

export type GetCrossingQueryVariables = Exact<{
  crossingCode: Scalars['String'];
}>;


export type GetCrossingQuery = { __typename?: 'Query', crossing: { __typename?: 'Crossing', id: number, code: string, count: number, result: number, failed: number, priority: number, program: string, father?: { __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } } | null, mother?: { __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } } | null } };

export type GetCrossingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCrossingsQuery = { __typename?: 'Query', crossings: Array<{ __typename?: 'Crossing', id: number, code: string, count: number, result: number, priority: number, program: string, site: string, seedsOrdered: number, seedsCounted: number, father?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null, mother?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null }> };

export type GetEventsQueryVariables = Exact<{
  crossingId?: InputMaybe<Scalars['Int']>;
  plotId?: InputMaybe<Scalars['Int']>;
}>;


export type GetEventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: number, type: EventType, count: number, user?: string | null, date: any, plotId?: number | null, crossingId?: number | null, delta: number, plot?: { __typename?: 'Plot', code: string } | null }> };

export type GetExportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetExportQuery = { __typename?: 'Query', export: { __typename?: 'Export', id: number, date: any, rows: Array<{ __typename?: 'ExportRow', breedingOperation?: string | null, breedingOperationType?: string | null, plot: string, date: any, count: number }> } };

export type GetExportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExportsQuery = { __typename?: 'Query', exports: Array<{ __typename?: 'Export', id: number, date: any }> };

export type GetPlotQueryVariables = Exact<{
  type: PlotType;
  plotCode: Scalars['String'];
}>;


export type GetPlotQuery = { __typename?: 'Query', plotByCode: { __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, crossingsFather: Array<{ __typename?: 'Crossing', id: number, code: string, count: number, result: number, priority: number, program: string, site: string, seedsOrdered: number, seedsCounted: number, father?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null, mother?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null }>, crossingsMother: Array<{ __typename?: 'Crossing', id: number, code: string, count: number, result: number, priority: number, program: string, site: string, seedsOrdered: number, seedsCounted: number, father?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null, mother?: { __typename?: 'Plot', code: string, year: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null } | null }>, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } } };

export type GetPlotsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlotsQuery = { __typename?: 'Query', plots: Array<{ __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } }> };

export type GetProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProgramsQuery = { __typename?: 'Query', programs: Array<{ __typename?: 'Program', id: number, code: string, year: number, priorities: Array<number>, activeEmasculationPriorities: Array<number>, endDate?: any | null }> };

export type GetReservedPlotsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetReservedPlotsQuery = { __typename?: 'Query', plots: Array<{ __typename?: 'Plot', id: number, code: string, year: number, available: number, reserved: number, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, crossingsFather: Array<{ __typename?: 'Crossing', mother?: { __typename?: 'Plot', code: string, available: number, position?: { __typename?: 'Position', id: number, locationNumber: number, table: number } | null } | null, reservations: Array<{ __typename?: 'Reservation', id: number, crossingId: number, date: any, count: number }> }> }> };

export type GetRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoleQuery = { __typename?: 'Query', role: { __typename?: 'GetRolePayload', role: UserRole } };

export type GetSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string, activeDates: Array<any> }> };

export type GetStockQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStockQuery = { __typename?: 'Query', plots: Array<{ __typename?: 'Plot', id: number, code: string, available: number, reserved: number, spareCount: number, type: PlotType, year: number, program?: string | null, site?: string | null, eventTotals: Array<{ __typename?: 'EventTotal', type: EventType, total: number }>, counts: { __typename?: 'PlotCounts', toSelf?: number | null, toCollect: number, available: number, toEmasculate: number }, position?: { __typename?: 'Position', locationNumber: number, table: number } | null, dates: { __typename?: 'PlotDates', onHold?: any | null, done?: any | null, removed?: any | null } }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, name: string, email: string, role: UserRole }> };

export type EventUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EventUpdatedSubscription = { __typename?: 'Subscription', eventUpdated: { __typename?: 'Event', id: number, type: EventType, count: number, user?: string | null, date: any, plotId?: number | null, crossingId?: number | null, delta: number, plot?: { __typename?: 'Plot', code: string } | null } };

export type EventUpdatedByCrossingSubscriptionVariables = Exact<{
  crossingId: Scalars['Int'];
}>;


export type EventUpdatedByCrossingSubscription = { __typename?: 'Subscription', eventUpdatedByCrossing: { __typename?: 'Event', id: number, type: EventType, count: number, user?: string | null, date: any, plotId?: number | null, crossingId?: number | null, delta: number, plot?: { __typename?: 'Plot', code: string } | null } };

export type EventUpdatedByPlotSubscriptionVariables = Exact<{
  plotId: Scalars['Int'];
}>;


export type EventUpdatedByPlotSubscription = { __typename?: 'Subscription', eventUpdatedByPlot: { __typename?: 'Event', id: number, type: EventType, count: number, user?: string | null, date: any, plotId?: number | null, crossingId?: number | null, delta: number, plot?: { __typename?: 'Plot', code: string } | null } };

export const CrossingInfoFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CrossingInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Crossing"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"father"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locationNumber"}},{"kind":"Field","name":{"kind":"Name","value":"table"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"mother"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locationNumber"}},{"kind":"Field","name":{"kind":"Name","value":"table"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"result"}},{"kind":"Field","name":{"kind":"Name","value":"priority"}},{"kind":"Field","name":{"kind":"Name","value":"program"}},{"kind":"Field","name":{"kind":"Name","value":"site"}},{"kind":"Field","name":{"kind":"Name","value":"seedsOrdered"}},{"kind":"Field","name":{"kind":"Name","value":"seedsCounted"}}]}}]} as unknown as DocumentNode<CrossingInfoFragment, unknown>;
export const EventFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Event"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Event"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"plotId"}},{"kind":"Field","name":{"kind":"Name","value":"crossingId"}},{"kind":"Field","name":{"kind":"Name","value":"delta"}},{"kind":"Field","name":{"kind":"Name","value":"plot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]} as unknown as DocumentNode<EventFragment, unknown>;
export const LogEventFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LogEvent"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Event"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"user"}},{"kind":"Field","name":{"kind":"Name","value":"plot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}}]}},{"kind":"Field","name":{"kind":"Name","value":"crossing"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}}]}}]}}]} as unknown as DocumentNode<LogEventFragment, unknown>;
export const PlotWithCrossingsFatherFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PlotWithCrossingsFather"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Plot"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locationNumber"}},{"kind":"Field","name":{"kind":"Name","value":"table"}}]}},{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"available"}},{"kind":"Field","name":{"kind":"Name","value":"reserved"}},{"kind":"Field","name":{"kind":"Name","value":"crossingsFather"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mother"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"available"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"locationNumber"}},{"kind":"Field","name":{"kind":"Name","value":"table"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"reservations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"crossingId"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]}}]} as unknown as DocumentNode<PlotWithCrossingsFatherFragment, unknown>;
export const PlotFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Plot"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Plot"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"counts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"toSelf"}},{"kind":"Field","name":{"kind":"Name","value":"toCollect"}},{"kind":"Field","name":{"kind":"Name","value":"available"}},{"kind":"Field","name":{"kind":"Name","value":"toEmasculate"}}]}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"locationNumber"}},{"kind":"Field","name":{"kind":"Name","value":"table"}}]}},{"kind":"Field","name":{"kind":"Name","value":"available"}},{"kind":"Field","name":{"kind":"Name","value":"reserved"}},{"kind":"Field","name":{"kind":"Name","value":"spareCount"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"dates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onHold"}},{"kind":"Field","name":{"kind":"Name","value":"done"}},{"kind":"Field","name":{"kind":"Name","value":"removed"}}]}},{"kind":"Field","name":{"kind":"Name","value":"program"}},{"kind":"Field","name":{"kind":"Name","value":"site"}}]}}]} as unknown as DocumentNode<PlotFragment, unknown>;
export const PlotWithEventTotalsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PlotWithEventTotals"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Plot"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Plot"}},{"kind":"Field","name":{"kind":"Name","value":"eventTotals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"total"}}]}}]}},...PlotFragmentDoc.definitions]} as unknown as DocumentNode<PlotWithEventTotalsFragment, unknown>;
export const ProgramFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Program"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Program"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"priorities"}},{"kind":"Field","name":{"kind":"Name","value":"activeEmasculationPriorities"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}}]}}]} as unknown as DocumentNode<ProgramFragment, unknown>;
export const SiteFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Site"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Site"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"activeDates"}}]}}]} as unknown as DocumentNode<SiteFragment, unknown>;
export const UserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]} as unknown as DocumentNode<UserFragment, unknown>;
export const AddEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EventType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"count"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reservationId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addEvent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"crossingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"count"},"value":{"kind":"Variable","name":{"kind":"Name","value":"count"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"reservationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reservationId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<AddEventMutation, AddEventMutationVariables>;
export const DeleteProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deleteProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const GenerateExportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"generateExport"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateExport"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"export"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]}}]}}]} as unknown as DocumentNode<GenerateExportMutation, GenerateExportMutationVariables>;
export const ImportSourceDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ImportSourceData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ImportSourceDataRowInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"importSourceData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rowsAdded"}},{"kind":"Field","name":{"kind":"Name","value":"fieldsChanged"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}}]} as unknown as DocumentNode<ImportSourceDataMutation, ImportSourceDataMutationVariables>;
export const UpdateEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"delta"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateEvent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"eventId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"eventId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"delta"},"value":{"kind":"Variable","name":{"kind":"Name","value":"delta"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateProgramDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateProgram"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"activeEmasculationPriorities"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"endDate"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProgram"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"activeEmasculationPriorities"},"value":{"kind":"Variable","name":{"kind":"Name","value":"activeEmasculationPriorities"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"endDate"},"value":{"kind":"Variable","name":{"kind":"Name","value":"endDate"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"program"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Program"}}]}}]}}]}},...ProgramFragmentDoc.definitions]} as unknown as DocumentNode<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const UpdateSiteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateSite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"activeDates"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Date"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateSite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"activeDates"},"value":{"kind":"Variable","name":{"kind":"Name","value":"activeDates"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"site"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Site"}}]}}]}}]}},...SiteFragmentDoc.definitions]} as unknown as DocumentNode<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"role"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserRole"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"role"},"value":{"kind":"Variable","name":{"kind":"Name","value":"role"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const FindEventsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"findEvents"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FindEventsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findEvents"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LogEvent"}}]}}]}},...LogEventFragmentDoc.definitions]} as unknown as DocumentNode<FindEventsQuery, FindEventsQueryVariables>;
export const GetCrossingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCrossing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"crossingCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"crossing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"crossingCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"crossingCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"father"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Plot"}}]}},{"kind":"Field","name":{"kind":"Name","value":"mother"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Plot"}}]}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"result"}},{"kind":"Field","name":{"kind":"Name","value":"failed"}},{"kind":"Field","name":{"kind":"Name","value":"priority"}},{"kind":"Field","name":{"kind":"Name","value":"program"}}]}}]}},...PlotFragmentDoc.definitions]} as unknown as DocumentNode<GetCrossingQuery, GetCrossingQueryVariables>;
export const GetCrossingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCrossings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"crossings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CrossingInfo"}}]}}]}},...CrossingInfoFragmentDoc.definitions]} as unknown as DocumentNode<GetCrossingsQuery, GetCrossingsQueryVariables>;
export const GetEventsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getEvents"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"plotId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"events"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"crossingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}}},{"kind":"Argument","name":{"kind":"Name","value":"plotId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"plotId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Event"}}]}}]}},...EventFragmentDoc.definitions]} as unknown as DocumentNode<GetEventsQuery, GetEventsQueryVariables>;
export const GetExportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getExport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"export"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"rows"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"breedingOperation"}},{"kind":"Field","name":{"kind":"Name","value":"breedingOperationType"}},{"kind":"Field","name":{"kind":"Name","value":"plot"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]}}]} as unknown as DocumentNode<GetExportQuery, GetExportQueryVariables>;
export const GetExportsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getExports"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exports"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]}}]} as unknown as DocumentNode<GetExportsQuery, GetExportsQueryVariables>;
export const GetPlotDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPlot"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PlotType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"plotCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plotByCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}},{"kind":"Argument","name":{"kind":"Name","value":"plotCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"plotCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Plot"}},{"kind":"Field","name":{"kind":"Name","value":"crossingsFather"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CrossingInfo"}}]}},{"kind":"Field","name":{"kind":"Name","value":"crossingsMother"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CrossingInfo"}}]}}]}}]}},...PlotFragmentDoc.definitions,...CrossingInfoFragmentDoc.definitions]} as unknown as DocumentNode<GetPlotQuery, GetPlotQueryVariables>;
export const GetPlotsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPlots"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plots"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"includeRemoved"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Plot"}}]}}]}},...PlotFragmentDoc.definitions]} as unknown as DocumentNode<GetPlotsQuery, GetPlotsQueryVariables>;
export const GetProgramsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPrograms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"programs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Program"}}]}}]}},...ProgramFragmentDoc.definitions]} as unknown as DocumentNode<GetProgramsQuery, GetProgramsQueryVariables>;
export const GetReservedPlotsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getReservedPlots"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"siteId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plots"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"EnumValue","value":"RESERVED"}},{"kind":"Argument","name":{"kind":"Name","value":"siteId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"siteId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlotWithCrossingsFather"}}]}}]}},...PlotWithCrossingsFatherFragmentDoc.definitions]} as unknown as DocumentNode<GetReservedPlotsQuery, GetReservedPlotsQueryVariables>;
export const GetRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getRole"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode<GetRoleQuery, GetRoleQueryVariables>;
export const GetSitesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSites"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sites"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Site"}}]}}]}},...SiteFragmentDoc.definitions]} as unknown as DocumentNode<GetSitesQuery, GetSitesQueryVariables>;
export const GetStockDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getStock"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plots"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"FATHER"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlotWithEventTotals"}}]}}]}},...PlotWithEventTotalsFragmentDoc.definitions]} as unknown as DocumentNode<GetStockQuery, GetStockQueryVariables>;
export const GetUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"User"}}]}}]}},...UserFragmentDoc.definitions]} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const EventUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"eventUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Event"}}]}}]}},...EventFragmentDoc.definitions]} as unknown as DocumentNode<EventUpdatedSubscription, EventUpdatedSubscriptionVariables>;
export const EventUpdatedByCrossingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"eventUpdatedByCrossing"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventUpdatedByCrossing"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"crossingId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"crossingId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Event"}}]}}]}},...EventFragmentDoc.definitions]} as unknown as DocumentNode<EventUpdatedByCrossingSubscription, EventUpdatedByCrossingSubscriptionVariables>;
export const EventUpdatedByPlotDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"eventUpdatedByPlot"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"plotId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventUpdatedByPlot"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"plotId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"plotId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Event"}}]}}]}},...EventFragmentDoc.definitions]} as unknown as DocumentNode<EventUpdatedByPlotSubscription, EventUpdatedByPlotSubscriptionVariables>;