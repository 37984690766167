import {Button, DatePicker, Form, Input, Select} from "antd";
import {useTranslate} from "hooks/useTranslate";
import {useQuery} from "@apollo/client";
import {
  FindEventsDocument,
  FindEventsInput,
  GetProgramsDocument,
  GetSitesDocument,
  LogEventFragment
} from "generated/graphql";
import {useMemo, useState} from "react";
import {DataTable} from "components/Table/DataTable";
import {Column} from "components/Table/Column";
import {DateTime} from "luxon";
import {range} from "helpers";

const { RangePicker } = DatePicker;

export const EventLog = () => {
  const { t } = useTranslate('log');
  const sites = useQuery(GetSitesDocument);
  const programs = useQuery(GetProgramsDocument);
  const [filter, setFilter] = useState<FindEventsInput | null>(null);

  const countryOptions = useMemo(() => [
    {
      value: null,
      label: ''
    },
  ...(sites.data?.sites.map(s => ({ value: s.id, label: s.name })) ?? [])
  ], [sites])

  const programOptions = useMemo(() => [
    {
      value: null,
      label: ''
    },
    ...(programs.data?.programs.map(s => ({ value: s.id, label: s.code })) ?? [])
  ], [programs])

  const yearOptions = useMemo(() => range(2023, new Date().getFullYear()).map(y => ({
    value: y,
    label: y.toString()
  })), []);

  const { data, loading } = useQuery(FindEventsDocument, {
    variables: { input: filter ?? {} },
    skip: !filter
  });
  
  const columns: Column<LogEventFragment>[] = [
    {
      value: a => a.date,
      render: a => <>{DateTime.fromISO(a.date).toLocaleString(DateTime.DATETIME_MED)}</>,
      key: 'date'
    },
    {
      value: a => DateTime.fromISO(a.date).toISODate(),
      key: 'date',
      exportOnly: true
    },
    {
      value: a => a.user,
      key: 'user',
      filter: true
    },
    {
      value: a => t(`types.${a.type.toString()}`),
      key: 'event',
      filter: true
    },
    {
      value: a => a.count,
      key: 'count'
    },
    {
      value: a => a.plot?.code,
      key: 'plot'
    },
    {
      value: a => a.crossing?.code,
      key: 'crossing'
    }
  ];

  return <>
    <Form labelCol={{ style: { width: 100 }  }}
          wrapperCol={{ style: { maxWidth: 250 } }}
          onFinish={val => setFilter({
            startDate: val.dates?.[0],
            endDate: val.dates?.[1],
            user: val.user,
            year: val.year,
            siteId: val.country,
            programId: val.program,
            plot: val.plot
          })}
          initialValues={{ year: new Date().getFullYear() }}
    >
      <Form.Item name="dates" label={t('dates')}>
        <RangePicker />
      </Form.Item>
      <Form.Item name="user" label={t('user')}>
        <Input />
      </Form.Item>
      <Form.Item name="year" label={t('year')}>
        <Select options={yearOptions} />
      </Form.Item>
      <Form.Item name="country" label={t('country')}>
        <Select options={countryOptions} />
      </Form.Item>
      <Form.Item name="program" label={t('program')}>
        <Select options={programOptions} />
      </Form.Item>
      <Form.Item name="plot" label={t('plot')}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ style: { marginLeft: 100 } }}>
        <Button loading={loading} type="primary" htmlType="submit">{t('search')}</Button>
      </Form.Item>
    </Form>

    { data && <DataTable source={data.findEvents} columns={columns} translatePrefix="log" /> }
  </>
}